<template>
  <div class="task-post-wrapper">
    <!-- Author + Timestamp -->
    <p class="task-post-meta">
      <strong class="is-size-7 has-margin-right-25">System</strong>
      <small class="has-text-grey-light">
        {{
          $moment(dateCreated).calendar(null, {
            sameElse: "DD/MM/YYYY"
          })
        }}
      </small>
    </p>

    <!-- Body -->
    <div class="task-post-body">
      <site-change-item :changes="changes" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteChangePost",
  components: {
    "site-change-item": () => import("@shared/sites/_siteChangeItem.vue")
  },
  props: {
    changes: {
      required: true,
      type: Array
    },
    dateCreated: {
      type: Date,
      required: true
    }
  }
};
</script>
